.arkon-zaufane-trust-badge {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 60px;

  &__image {
    display: flex;
    flex-direction: column;

    align-items: center;

    margin-right: 15px;

    img {
      width: 100px;
      height: auto;

      object-fit: contain;
    }
  }

  &__content {
    flex: 1;
  }

  &__title {
    color: black;

    font-weight: 600;
    font-size: 1.8rem;

    margin: 0 0 5px 0;
  }

  &__description {
    font-size: 1.4rem;

    margin: 0 0 8px 0;
  }

  &__link {
    font-size: 1.2rem;
    text-decoration: underline;

    margin-top: 15px;

    cursor: pointer;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    text-align: center;

    &__image {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.arkon-zaufane-reviews {
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 100%;

  .review {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;

    order: 0;

    &__header {
      .rating {
        .checked {
          color: #f7b500;
        }
      }
      .date {
        color: var(--color-gray-4);
      }
    }

    &.empty {
      border-bottom: unset;
    }
  }

  .widget {
    order: 1;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    row-gap: 30px;

    .review {
      order: 1;
    }

    .widget {
      order: 0;

      .arkon-zaufane-trust-badge {
        padding: 0 20px;

        &__image {
          order: 1;
          margin-top: 10px;
        }
      }
    }
  }
}

.arkon-zaufane-reviews-rating {
  display: flex;
  flex-direction: row;

  .product-rating {
    position: relative;
    display: flex;
    width: 8.75rem;
    margin-right: 5px;

    .star {
      width: 1.75rem;
      position: relative;
      white-space: nowrap;
      fill: #f7b500;
    }

    .cover {
      background: #666;
      height: 100%;
      overflow: hidden;
      mix-blend-mode: color;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .info {
    font-size: 1.5rem;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.product-description {
  .arkon-zaufane-reviews-rating {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 5px;

    .product-rating {
      position: relative;
      display: flex;
      width: 8rem;
      margin-right: 5px;
      background-color: white;
      padding-bottom: 2.5px;

      .star {
        width: 1.6rem;
        position: relative;
        white-space: nowrap;
        fill: #f7b500;
      }

      .cover {
        background: #666;
        height: 100%;
        overflow: hidden;
        mix-blend-mode: color;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .info {
      font-size: 1.4rem;

      span {
        text-decoration: unset;
        cursor: unset;
      }
    }
  }
}

.arkon-zaufane-shop-rating {
  display: flex;
  flex-direction: row;

  .shop-rating-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .shop-rating {
      position: relative;
      display: flex;
      width: 8.75rem;
      margin-right: 5px;

      .star {
        width: 1.75rem;
        position: relative;
        white-space: nowrap;
        fill: #f7b500;
      }

      .cover {
        background: #666;
        height: 100%;
        overflow: hidden;
        mix-blend-mode: color;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .info {
      font-size: 1.5rem;
      display: flex;
      flex-direction: column;

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
